export const environment = {
  production: true,
  serverUrl: "https://clownfish-app-ys3sj.ondigitalocean.app/",
  // serverUrl: "https://api.citizenslottery.com/",

  // serverUrl: "http://159.65.88.143:4001/",

  log: false,
  flags: {
    useNewHeader: false
  }
};
// http://localhost:4001/
// serverUrl: "https://api.citizenslottery.com/",
